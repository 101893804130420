<template>
  <div ref="document">
    <div id="element-to-convert" class="bg-black">
      <div class="h-full p-16">
        <div class="flex justify-between">
          <img :src="require(`@/assets/certificate/logo.svg`)" alt="logo" />
          <p>
            {{
              achievement ? formatDate(achievement.et) : "13th September 2023"
            }}
          </p>
        </div>
        <div class="flex justify-center relative">
          <img
            alt="Lottie File"
            height="160px"
            class="z-10 mb-4 h-24 md:h-44"
            :class="{
              'mt-8': selectedLevel === '1st',
              'mt-5': selectedLevel === '2nd',
              'mt-12': selectedLevel === '3rd',
            }"
            :src="
              selectedLevel === '1st'
                ? require(`@/assets/achievements/level-1-active.svg`)
                : require(`@/assets/achievements/level-2-active.svg`)
            "
          />
          <p
            class="absolute text-small md:text-2xl bottom-1/3 md:bottom-16 font-extrabold text-gray-500 z-20"
          >
            {{ selectedChallenge }}
          </p>
        </div>
        <div
          class="text-center mx-auto"
          :class="{
            'max-w-3xl': selectedLevel === '1st' || selectedLevel === '3rd',
            'max-w-4xl': selectedLevel === '2nd',
          }"
        >
          <p class="text-2xl font-extrabold title">WFYT Challenge</p>
          <p class="text-6xl font-extrabold subtitle">
            {{ selectedLevel == "3rd" ? "PROFIT SPLIT" : "CERTIFICATE" }}
          </p>
          <p class="text-white mb-5 mt-10 text-3xl">{{ selectedAwardee }}</p>
          <p v-if="selectedLevel === '1st'">
            Congratulations {{ selectedAwardee }} on completing Level 1 of the
            We Fund You Trade Trading Challenge. Your dedication and trading
            acumen have set you apart. This certificate acknowledges your
            achievement and mastery of foundational trading principles. Well
            done and onward to further success!
          </p>
          <p v-if="selectedLevel === '2nd'">
            Congratulations, {{ selectedAwardee }}! This certificate is proudly
            presented to acknowledge that you have successfully completed Level
            2 of the Trading Challenge with We Fund You Trade. With exceptional
            dedication, insight, and acumen, {{ selectedAwardee }} has
            proficiently navigated through intricate market dynamics and has
            earned the esteemed status of a Funded Trader. We commend
            {{ selectedAwardee }} for this significant accomplishment and
            anticipate continued success in future trading endeavours.
          </p>
          <!-- <p v-if="selectedLevel == '3rd'" class="text-6xl font-extrabold">
              $ 16,000.00
            </p> -->
        </div>
        <div
          class="flex justify-end"
          :class="{
            'mt-16': selectedLevel === '1st',
            'mt-12': selectedLevel === '2nd',
            'mt-20': selectedLevel === '3rd',
          }"
        >
          <img
            :src="require(`@/assets/certificate/signature.svg`)"
            alt="logo"
            class="flex self-center mr-2 h-14"
          />
          <div class="text-left signatory">
            <p class="text-lg font-bold">Dan Beckerleg</p>
            <p class="text-xs">Representative,</p>
            <p class="italic text-xs">We Fund You Trade</p>
          </div>
        </div>
      </div>
    </div>
    <button @click="exportToPDF" class="text-white">Export to PDF</button>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  name: "Certificate",
  props: {
    level: {
      type: String,
      default: "1st",
    },
    challenge: {
      type: String,
      default: "10K",
    },
    achievement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedLevel:
        this.$route.query.s == 1
          ? "1st"
          : this.$route.query.s == 2
          ? "2nd"
          : this.level,
      selectedChallenge: this.$route.query.c ?? this.challenge,
      selectedAwardee: this.achievement ? this.achievement.nn : "No Name Given",
    };
  },
  watch: {
    achievement: {
      handler(newValue) {
        console.log("ac: ", newValue);
      },
    },
  },
  methods: {
    exportToPDF() {
      html2pdf(document.getElementById("element-to-convert"), {
        margin: 1,
        jsPDF: { orientation: "landscape" },
        filename: `WFYT ${this.selectedChallenge} Challenge Certificate - ${this.selectedLevel} Level.pdf`,
      });
    },
    formatDate(date) {
      var d = new Date(date);
      var month = d.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      var this_date = d.getDate();
      var dateExt = this.getDateExt(this_date);

      var formated_date = this_date + dateExt + " " + month;

      return formated_date;
    },
    getDateExt(date) {
      if (date > 3 && date < 21) return "th";
      switch (date % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#element-to-convert {
  color: #e1d1b4;
}
.title {
  letter-spacing: 3.2px;
}
.subtitle {
  letter-spacing: 7.68px;
}
.signatory {
  color: #cce7ea;
}
</style>

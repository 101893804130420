<template>
  <div
    v-if="isShared"
    class="mx-auto max-w-2xl h-screen flex items-center w-min"
  >
    <ShareAchievement
      :level="level"
      :challenge="selectedChallenge"
      @hideShare="hideShare"
    />
  </div>
  <div v-else class="text-white overflow-auto mx-auto max-w-5xl">
    <div class="border-b border-gray-900 px-4 py-4 sm:px-6">
      <div
        class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap"
      >
        <div class="ml-4 mt-2">
          <h3 class="text-xs md:text-base md:leading-6">MY ACHIEVEMENTS</h3>
        </div>
      </div>
    </div>
    <nav
      class="items-center mt-4 divide-x divide-selected rounded-2xl md:w-1/2 grid grid-cols-4 px-4 sm:px-0"
      aria-label="Tabs"
    >
      <a
        v-for="(record, index) in records"
        :key="index"
        @click="selectedChallenge = record.challenge"
        :class="{
          'bg-selected font-medium': selectedChallenge === record.challenge,
          'text-white bg-default': selectedChallenge !== record.challenge,
          'rounded-l-2xl': index === 0,
          'rounded-r-2xl': index === records.length - 1,
        }"
        class="cursor-pointer text-white group font-light relative min-w-0 sm:flex-1 overflow-hidden py-2 px-2 md:px-4 text-center text-sm hover:bg-selected focus:z-10"
        aria-current="page"
      >
        <span class="uppercase">{{ "$ " + record.challenge }}</span>
      </a>
    </nav>
    <div v-for="record in records" :key="`ranking-${record.challenge}`">
      <div
        v-if="record.challenge == selectedChallenge"
        class="grid grid-cols-3 mt-2 md:mt-0"
      >
        <div class="flex flex-col items-center justify-center self-end">
          <div class="flex justify-center relative">
            <img
              v-if="
                record.achievements.length > 0 &&
                record.achievements[0].achievement > 0
              "
              alt="Lottie File"
              height="160px"
              class="z-10 mb-4 h-24 md:h-44"
              :src="require(`@/assets/achievements/level-1-active.svg`)"
            />
            <img
              v-else
              alt="Lottie File"
              height="160px"
              class="z-10 mb-4 h-24 md:h-44 opacity-50"
              :src="require(`@/assets/achievements/level-1.svg`)"
            />
            <p
              class="absolute text-small md:text-2xl bottom-1/3 md:bottom-16 font-extrabold text-gray-500 z-20"
            >
              {{ selectedChallenge }}
            </p>
          </div>
          <div class="flex justify-center relative">
            <img
              alt="Lottie File"
              height="160px"
              class="z-10 w-20 md:w-auto"
              :src="require(`@/assets/achievements/level-1-stand.svg`)"
            />

            <p
              v-if="
                record.achievements.length > 0 &&
                record.achievements[0].achievement > 0
              "
              class="absolute z-20 md:text-xs text-gray-500 mt-3 md:mt-4 level-date mx-5 md:mx-8"
            >
              {{ formatDate(record.achievements[0].st) }}
            </p>
          </div>
        </div>
        <div class="flex flex-col items-center justify-center self-end">
          <div class="flex justify-center relative">
            <img
              v-if="
                record.achievements.length > 0 &&
                record.achievements[0].achievement >= 2
              "
              alt="Lottie File"
              height="160px"
              class="z-10 mb-4 h-24 md:h-44"
              :src="require(`@/assets/achievements/level-2-active.svg`)"
            />
            <img
              v-else
              alt="Lottie File"
              height="160px"
              class="z-10 mb-4 opacity-50 h-24 md:h-44"
              :src="require(`@/assets/achievements/level-2.svg`)"
            />
            <p
              class="absolute text-small md:text-2xl bottom-1/3 md:bottom-16 font-extrabold text-gray-500 z-20"
            >
              {{ selectedChallenge }}
            </p>
          </div>
          <div class="flex justify-center relative">
            <img
              alt="Lottie File"
              height="160px"
              class="z-10 w-20 md:w-auto"
              :src="require(`@/assets/achievements/level-2-stand.svg`)"
            />

            <p
              class="absolute z-20 md:text-xs text-gray-500 mt-3 md:mt-4 level-date mx-5 md:mx-8"
              v-if="
                record.achievements.length > 0 &&
                record.achievements[0].achievement >= 2
              "
            >
              {{ formatDate(record.achievements[0].st) }}
            </p>
          </div>
        </div>
        <div class="flex flex-col items-center justify-center">
          <div class="flex justify-center relative">
            <img
              v-if="
                record.achievements.length > 0 &&
                record.achievements[0].achievement == 3
              "
              alt="Lottie File"
              height="160px"
              class="z-10 mb-4 h-24 md:h-44"
              :src="require(`@/assets/achievements/level-3-active.svg`)"
            />
            <img
              v-else
              alt="Lottie File"
              height="160px"
              class="z-10 mb-4 h-24 md:h-44 opacity-50"
              :src="require(`@/assets/achievements/level-3.svg`)"
            />
            <p
              class="absolute text-small md:text-2xl bottom-1/3 md:bottom-16 font-extrabold text-gray-500 z-20"
            >
              {{ selectedChallenge }}
            </p>
          </div>
          <div class="flex justify-center relative">
            <img
              alt="Lottie File"
              height="160px"
              class="z-10 w-20 md:w-auto"
              :src="require(`@/assets/achievements/level-3-stand.svg`)"
            />
            <p
              class="absolute z-20 md:text-xs text-gray-500 mt-3 md:mt-4 level-date mx-5 md:mx-8"
              v-if="
                record.achievements.length > 0 &&
                record.achievements[0].achievement == 3
              "
            >
              {{ formatDate(record.achievements[0].st) }}
            </p>
          </div>
        </div>
        <p class="level-ground"></p>
      </div>
      <div
        v-if="record.challenge == selectedChallenge"
        class="flex items-center grid mx-5 gap-x-10"
        :class="{
          'grid-cols-1':
            record.achievements.length > 0 &&
            record.achievements[0].achievement == 3,
          'grid-cols-3':
            record.achievements.length > 0 &&
            record.achievements[0].achievement != 3,
        }"
      >
        <div
          class="flex-wrap items-center justify-center"
          v-if="
            record.achievements.length > 0 &&
            record.achievements[0].achievement != 3
          "
        >
          <p class="text-xs md:text-sm level-message md:mb-5">
            Congratulations! You have earned your first badge and you have
            become an expert trader!
          </p>
          <div class="flex flex-wrap justify-center gap-y-1.5">
            <button
              v-if="currentEnv !== 'production'"
              @click="downloadPdf('1st')"
              type="button"
              class="flex-shrink-0 justify-center inline-flex items-center gap-x-1.5 w-full mx-5 rounded-md bg-primary py-0.5 md:py-2 md:text-sm text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              <ArrowDownTrayIcon class="h-4 w-4 my-0.5" />
              <span>Download</span>
            </button>
            <button
              type="button"
              @click="(isShared = true), (level = '1st')"
              class="flex-shrink-0 justify-center inline-flex items-center gap-x-1.5 w-full mx-5 rounded-md bg-primary py-0.5 md:py-2 md:text-sm text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              <ShareIcon class="h-4 w-4 my-0.5" />
              <span>Share</span>
            </button>
          </div>
        </div>
        <div
          class="flex-wrap items-center justify-center"
          v-if="
            record.achievements.length > 0 &&
            record.achievements[0].achievement == 2
          "
        >
          <p class="text-xs md:text-sm level-message md:mb-5">
            Congratulations! You have earned your second badge and you have
            become a master trader!
          </p>
          <div class="flex flex-wrap justify-center gap-y-1.5">
            <button
              v-if="currentEnv !== 'production'"
              @click="downloadPdf('2nd')"
              type="button"
              class="flex-shrink-0 justify-center inline-flex items-center gap-x-1.5 w-full mx-5 rounded-md bg-primary py-0.5 md:py-2 md:text-sm text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              <ArrowDownTrayIcon class="h-4 w-4 my-0.5" />
              <span>Download</span>
            </button>
            <button
              type="button"
              @click="(isShared = true), (level = '2nd')"
              class="flex-shrink-0 justify-center inline-flex items-center gap-x-1.5 w-full mx-5 rounded-md bg-primary py-0.5 md:py-2 md:text-sm text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              <ShareIcon class="h-4 w-4 my-0.5" />
              <span>Share</span>
            </button>
          </div>
        </div>
        <div
          class="flex-wrap items-center justify-center"
          v-if="
            record.achievements.length > 0 &&
            record.achievements[0].achievement == 3
          "
        >
          <p class="text-xs md:text-sm level-message md:mb-5">
            Congratulations! You have earned your third badge and you have
            become a pro trader!
          </p>
          <div class="flex flex-wrap justify-center gap-y-1.5 mx-auto w-1/4">
            <!-- completely hidden for now -->
            <!-- <button
              @click="downloadPdf('3rd')"
              type="button"
              class="flex-shrink-0 justify-center inline-flex items-center gap-x-1.5 w-full mx-5 rounded-md bg-primary py-0.5 md:py-2 md:text-sm text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              <ArrowDownTrayIcon class="h-4 w-4 my-0.5" />
              <span>Download</span>
            </button>-->
            <button
              type="button"
              @click="(isShared = true), (level = '3rd')"
              class="flex-shrink-0 justify-center inline-flex items-center gap-x-1.5 w-full mx-5 rounded-md bg-primary py-0.5 md:py-2 md:text-sm text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              <ShareIcon class="h-4 w-4 my-0.5" />
              <span>Share</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Certificate
      :level="pdfLevel"
      :challenge="selectedChallenge"
      :achievement="achievement"
      class="hidden"
      ref="certificate"
    />
  </div>
</template>

<script>
import { ShareIcon, ArrowDownTrayIcon } from "@heroicons/vue/24/solid";
import ShareAchievement from "@/components/ShareAchievement.vue";
import Certificate from "@/views/Certificate.vue";

export default {
  components: {
    ShareIcon,
    ArrowDownTrayIcon,
    ShareAchievement,
    Certificate,
  },
  created() {
    this.metaDescription = `Hey everyone! I have just passed the ${
      this.level
    } stage on #WFYTtradechallenge ${
      this.level === "1st"
        ? "two more to become a Pro!"
        : this.level === "2nd"
        ? "one more to become a Pro!"
        : "and now a Pro!"
    } `;

    switch (this.level) {
      case "1st":
        this.banner = require(`@/assets/share/level-1.svg`);
        break;
      case "2nd":
        this.banner = require(`@/assets/share/level-2.svg`);
        break;
      default:
        this.banner = require(`@/assets/share/level-3.svg`);
        break;
    }
    const metas = Array.from(document.getElementsByTagName("meta"));

    const metaImage = metas.find(
      (m) => m.attributes[0].nodeValue === "og:image"
    );
    metaImage.attributes[1].nodeValue = window.location.origin + this.banner;

    const twitterMetaImage = metas.find(
      (m) => m.attributes[0].nodeValue === "twitter:image"
    );
    twitterMetaImage.attributes[1].nodeValue =
      window.location.origin + this.banner;

    const metaDesc = metas.find(
      (m) => m.attributes[0].nodeValue === "og:description"
    );
    metaDesc.attributes[1].nodeValue = this.metaDescription;

    const twitetrMetaDesc = metas.find(
      (m) => m.attributes[0].nodeValue === "twitter:description"
    );
    twitetrMetaDesc.attributes[1].nodeValue = this.metaDescription;
  },
  mounted() {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data.value !== undefined) {
          this.records = event.data.value;
          this.dummy = event.data.value;
        }

        if (this.records !== undefined) {
          this.records.sort((a, b) => b.st - a.st);

          /**
           * Add achievement property based on type
           * stagel = 0
           * stage2 = 1
           * funded = 2
           * funded, flg:512 = 3
           */
          for (const item of this.records) {
            if (item.type.includes("stage2")) {
              item.achievement = 1;
            } else if (item.type.includes("funded")) {
              if (item.flg & 512) {
                item.achievement = 3;
              } else {
                item.achievement = 2;
              }
            } else {
              item.achievement = 0;
            }
          }

          /**
           * Group achievements by type
           */
          const groupedItems = [];

          for (const item of this.records) {
            const typeMatch = item.type.match(/\d+K/); // Extract the challenge (e.g., 50K, 100K, 200K, 300K)

            if (typeMatch) {
              const type = typeMatch[0];
              const existingGroup = groupedItems.find(
                (group) => group.challenge === type
              );

              // Check for specific conditions to include the achievement
              const newItem = item.achievement > 0 ? [item] : [];

              if (!existingGroup) {
                groupedItems.push({
                  challenge: type,
                  achievements: newItem,
                });
              } else {
                // Check if the item type is already in the achievements
                if (
                  !existingGroup.achievements.some(
                    (ach) => ach.type === item.type
                  )
                ) {
                  existingGroup.achievements.push(item);
                }
              }
            }
          }

          this.records = groupedItems;
          this.records.sort(function (a, b) {
            return a.challenge.slice(0, -1) - b.challenge.slice(0, -1);
          });
          this.selectedChallenge = this.records[0].challenge;
          this.achievement = this.records[0]
            ? this.records[0].achievements[0]
            : null;
        }
      },
      false
    );
  },
  data() {
    return {
      selectedChallenge: this.$route.query.c,
      records: {},
      dummy: {},
      isShared: false,
      level: "3rd",
      pdfLevel: "3rd",
      currentEnv: process.env.VUE_APP_ENV ?? "production",
      achievement: null,
      banner: null,
      metaDescription: null,
    };
  },
  methods: {
    formatDate(date) {
      var d = new Date(date);
      var month = d.toLocaleString("default", {
        month: "short",
        year: "numeric",
      });
      var this_date = d.getDate();
      var dateExt = this.getDateExt(this_date);

      var formated_date = this_date + dateExt + " " + month;

      return formated_date;
    },
    getDateExt(date) {
      if (date > 3 && date < 21) return "th";
      switch (date % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    },
    hideShare() {
      this.isShared = false;
    },
    downloadPdf(level) {
      this.pdfLevel = level;
      this.$refs.certificate.exportToPDF();
    },
  },
};
</script>

<style lang="postcss" scoped>
.level-ground {
  background: linear-gradient(90deg, #222 0%, #1e1e1e 100%);
  @apply h-10 md:h-14 col-span-3 mb-2 md:mb-5 -mt-7;
}
@media (max-width: 768px) {
  .level-date {
    font-size: 7px !important;
  }
  .level-message {
    font-size: 9px !important;
    @apply mb-2;
  }
}
</style>

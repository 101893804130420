<template>
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-white flex">
    <div class="mx-auto max-w-2xl h-screen flex items-center">
      <ShareAchievement
        v-if="isShared"
        :level="level"
        :challenge="challenge"
        @hideShare="hideShare"
      />

      <div v-else class="py-10 flex-wrap items-center justify-center">
        <p class="text-2xl md:text-4xl md:leading-7 sm:tracking-tight">
          Congratulations
        </p>
        <div class="flex items-center justify-center my-2">
          <NewAchievementMotion
            ref="newAchievementMotion"
            :challenge="challenge"
            :level="level"
            :loop="true"
          />
        </div>
        <p class="text-xs md:text-sm level-message mb-5">
          You have passed the {{ level }} level of the WFYT Challenge and
          obtained a certificate!
        </p>
        <div class="grid justify-center gap-y-1.5">
          <button
            v-if="level !== '3rd' && currentEnv !== 'production'"
            type="button"
            @click="downloadPdf(level)"
            class="justify-center inline-flex items-center gap-x-1.5 rounded-md bg-primary px-8 py-0.5 md:py-2 md:text-sm text-xs text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            <ArrowDownTrayIcon class="h-4 w-4 my-0.5" />
            <span>Download</span>
          </button>
          <button
            type="button"
            @click="isShared = true"
            class="justify-center inline-flex items-center gap-x-1.5 rounded-md bg-primary px-6 py-0.5 md:py-2 md:text-sm text-xs text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          >
            <ShareIcon class="h-4 w-4 my-0.5" />
            <span>Share</span>
          </button>
        </div>
      </div>
    </div>
    <Certificate
      :level="level"
      :challenge="challenge"
      class="hidden"
      ref="certificate"
    />
  </div>
</template>

<script>
import { ShareIcon } from "@heroicons/vue/24/solid";
import { ArrowDownTrayIcon } from "@heroicons/vue/24/solid";
import NewAchievementMotion from "@/components/NewAchievementMotion.vue";
import ShareAchievement from "@/components/ShareAchievement.vue";
import Certificate from "@/views/Certificate.vue";

export default {
  components: {
    ShareIcon,
    ArrowDownTrayIcon,
    NewAchievementMotion,
    ShareAchievement,
    Certificate,
  },
  data() {
    return {
      level:
        this.$route.query.s == 1
          ? "1st"
          : this.$route.query.s == 2
          ? "2nd"
          : "3rd",
      name:
        this.$route.query.s == 1
          ? "EXPERT"
          : this.$route.query.s == 2
          ? "MASTER"
          : "PRO",
      challenge: this.$route.query.c ?? "",
      currentEnv: process.env.VUE_APP_ENV ?? "production",
      isShared: false,
      banner: null,
      metaDescription: null,
    };
  },
  methods: {
    hideShare() {
      this.isShared = false;
    },
    downloadPdf(level) {
      this.level = level;
      this.$refs.certificate.exportToPDF();
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      deep: true,
      handler(query) {
        this.challenge = query.c ?? "";
        switch (query.s) {
          case "1": {
            this.level = "1st";
            this.name = "EXPERT";
            break;
          }
          case "2": {
            this.level = "2nd";
            this.name = "MASTER";
            break;
          }
          case "3": {
            this.level = "3rd";
            this.name = "PRO";
            break;
          }
          default: {
            this.level = "3rd";
            this.name = "PRO";
            break;
          }
        }
        this.$nextTick(() => {
          let self = this;
          self.$refs.newAchievementMotion.restart();
        });
      },
    },
  },
  created() {
    this.metaDescription = `Hey everyone! I have just passed the ${
      this.level
    } stage on #WFYTtradechallenge ${
      this.level === "1st"
        ? "two more to become a Pro!"
        : this.level === "2nd"
        ? "one more to become a Pro!"
        : "and now a Pro!"
    } `;

    switch (this.level) {
      case "1st":
        this.banner = require(`@/assets/share/level-1.svg`);
        break;
      case "2nd":
        this.banner = require(`@/assets/share/level-2.svg`);
        break;
      default:
        this.banner = require(`@/assets/share/level-3.svg`);
        break;
    }
    const metas = Array.from(document.getElementsByTagName("meta"));

    const metaImage = metas.find(
      (m) => m.attributes[0].nodeValue === "og:image"
    );
    metaImage.attributes[1].nodeValue = window.location.origin + this.banner;

    const twitterMetaImage = metas.find(
      (m) => m.attributes[0].nodeValue === "twitter:image"
    );
    twitterMetaImage.attributes[1].nodeValue =
      window.location.origin + this.banner;

    const metaDesc = metas.find(
      (m) => m.attributes[0].nodeValue === "og:description"
    );
    metaDesc.attributes[1].nodeValue = this.metaDescription;

    const twitetrMetaDesc = metas.find(
      (m) => m.attributes[0].nodeValue === "twitter:description"
    );
    twitetrMetaDesc.attributes[1].nodeValue = this.metaDescription;
  },
};
</script>

<style lang="postcss" scoped>
h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 40px */
}
@media (max-width: 640px) {
  .level-message {
    font-size: 9px !important;
  }
}
</style>

<script setup>
import { Vue3Lottie } from "vue3-lottie";
import levelOneJson from "@/assets/lottiefiles/level-1.json";
import levelTwoJson from "@/assets/lottiefiles/level-2.json";
import levelThreeJson from "@/assets/lottiefiles/level-3.json";
import { defineProps, ref, onMounted, defineExpose, onUpdated } from "vue";

const props = defineProps({
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "15rem",
  },
  speed: {
    type: Number,
    default: 1,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  challenge: {
    type: String,
    default: "50K",
  },
  level: {
    type: String,
    default: "1st",
  },
});

const newAchievement = ref(null);
let currentJson = ref(null);

const restart = () => {
  newAchievement.value.goToAndPlay(0, true);
};
const updateAnimation = () => {
  currentJson.value =
    props.level == "1st"
      ? levelOneJson
      : props.level == "2nd"
      ? levelTwoJson
      : levelThreeJson;

  currentJson.value.assets[4].layers[4].t.d.k[0].s.t = props.challenge;
  // newAchievement.value.updateDocumentData({ t: '50k' }, 5);
};

onMounted(() => {
  updateAnimation();
});

onUpdated(() => {
  updateAnimation();
});

defineExpose({
  restart,
});
</script>

<template>
  <div>
    <Vue3Lottie
      :animationData="currentJson"
      :width="props.width"
      :height="props.height"
      :speed="props.speed"
      :loop="props.loop"
      ref="newAchievement"
    />
  </div>
</template>
<style lang="postcss" scoped>
/deep/div.lottie-animation-container {
  margin: 1rem auto;
}
/deep/div.lottie-animation-container svg {
  min-height: 150px;
  margin-left: auto;
  margin-right: auto;
}
</style>
